<template>
    <div  v-disable-all="!pageMeta.permissions.edit">
        <div v-if='!loading' class='rec-data-div' :class="pageMeta.component_name.toLowerCase()+'-page'">
            <form @submit.prevent='updateRecord'>
                <div class='flex space-between'>
                    <div class='name-id'>
                        <FormClient :client_id='client_id' :form_data_id='record_id' @getClient='getClient' />
                        <br>
                    </div>
                    <div class='align-right'>
                        <FormCompany @getCompany='getCompany' />
                    </div>
                </div>
                <div>
                    <h2 class='margin-0'>{{ pageMeta.title }}</h2>
                </div>

                <button type='button' @click='signature_ids=[]'
                        v-if='signature_ids.length && original_signature_ids.length'>
                    Edit & Create Addendum
                </button>
                <button v-if='original_signature_ids.length && !signature_ids.length' class='align-right' type='button'
                        @click='signature_ids=original_signature_ids'>
                    Cancel Edit
                </button>
                <div class='dark-text header-w-bg addendum' v-if='original_signature_ids.length && !signature_ids.length'>
                    You are now editing the original signed document to create an addendum
                </div>
                <hr class='m-15'>
                <div class="align-right do-not-print-me">
                    <button class="secondary" type="button" @click="printView()">
                        Print View
                    </button>
                </div>
                <!-- staff -->
                <div class='flex space-between colx3'>
                    <div>
                        <label>Staff Present</label>
                        <Multiselect
                            class='max500'
                            v-model='rec.staffPresent'
                            :options='clinicians'
                            label='name'
                            track-by='id'
                            :closeOnSelect='true'
                            :custom-label='clinicianName'
                            :allow-empty='false'
                            :show-labels='false'
                            :disabled='hasSignature'
                            placeholder='Pick a staff'
                        />
                    </div>
                    <div>
                        <div>
                            <label for='dateSpecimenCollection'>Date of Specimen Collection</label>
                            <input type='date'
                                   class='max500'
                                   id='dateSpecimenCollection'
                                   name='dateSpecimenCollection'
                                   v-model='rec.dateSpecimenCollection'
                                   :default-date='!rec.dateSpecimenCollection ? rec.dateSpecimenCollection = new Date(dayt.getTime() - dayt.getTimezoneOffset()*60000).toISOString().slice(0,10) : rec.dateSpecimenCollection'
                                   :disabled='hasSignature'
                                   required
                            >
                        </div>
                    </div>
                    <div>
                        <label for='verify-id'> ID Verification Method Used </label>
                        <select id='verify-id' v-model='rec.idVerification' :disabled='hasSignature'>
                            <option value=''> Select an option</option>
                            <option value='Known to Collector'> Known to Collector</option>
                            <option value='Driver License'> Driver License</option>
                            <option value='Passport'> Passport</option>
                            <option value='Photo ID'> Photo ID</option>
                        </select>
                    </div>
                </div>

                <!-- M E D I C A T I O N S -->
                <div class="avoid-break">
                    <div class='dark-text header-w-bg'>Medications</div>
                    <label>List Any Medications on File or Reported</label>
                    <Multiselect v-model='rec.medications.medsOnFile'
                                 class=''
                                 :options='medsOptions'
                                 :closeOnSelect='false'
                                 :showNoResults='false'
                                 :searchabe='false'
                                 :multiple='true'
                                 :taggable='true'
                                 :disabled='hasSignature'
                                 track-by='code'
                                 label='name'
                                 @tag='addMedTag'
                                 @remove='removeMedTag'
                                 placeholder=''>
                        <span slot='noOptions'>Type medications here...</span>
                    </Multiselect>
                </div>

                <!-- R E P O R T E D   D R U G    U S E  -->
                <div class="avoid-break">
                    <div class='dark-text header-w-bg'>Reported Use of Drugs or Other Intoxicants Within the Last 14
                        Days
                    </div>
                    <label>List Any Drugs or Intoxicants Reported and Date Used</label>
                    <multiselect 
                        v-model='rec.medications.reportedIntoxicants'
                        class=''
                        :options='drugsOptions'
                        label='name'
                        :multiple='true'
                        :disabled='hasSignature'
                        :taggable='true'
                        @tag='addDrugTag'
                        @remove='removeDrugTag'
                        track-by='code'
                        :closeOnSelect='false'
                        placeholder='Type intoxicants reported here'>
                        <span slot='noOptions'>List Drugs here...</span>
                    </multiselect>
                </div>

                <!-- C O L L E C T I O N    D E E T S  -->
                <div>
                    <div class='dark-text header-w-bg'>Collection Details</div>
                    <div class='flex space-between'>
                        <div class="pad-5">
                            <div class='dark-text bottom-10 weight-600'>Method of Screening</div>
                            <div class=''>
                                <div v-for='(option, key) in screeningOptions' :key='option'
                                     class='right-20 flex center bottom-10'>
                                    <input class="color" type='radio'
                                           :id='key'
                                           :name='option'
                                           :value='screeningOptions[key]'
                                           v-model='rec.collectionDetails.screenMethod'
                                           :disabled='hasSignature'
                                    >
                                    <label :for='key'>{{ option }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="pad-5">
                            <div class='dark-text bottom-10 weight-600'>Collection Observed</div>
                            <div class="">
                                <div class='flex center right-20 bottom-10'>
                                    <input class="color" type='radio' id='isObserved' name='yes' value='Yes' :disabled='hasSignature'
                                           v-model='rec.collectionDetails.observed' />
                                    <label for='isObserved' class='bottom-0 right-20'> Yes</label>
                                </div>
                                <div class='flex center'>
                                    <input class='left-20 color' type='radio' id='notObserved' name='no' value='No'
                                           :disabled='hasSignature'
                                           v-model='rec.collectionDetails.observed' />
                                    <label class='bottom-0' for='notObserved'> No </label>
                                </div>
                            </div>
                        </div>
                        <div class="pad-5">
                            <div class='dark-text bottom-10 weight-600'>Sent to Outside lab?</div>
                            <div class=''>
                                <div class='flex center right-20 bottom-10'>
                                    <input class="color" type='radio' id='isSent' name='isSent' value='Yes'
                                           v-model='rec.collectionDetails.isSent' :disabled='hasSignature' />
                                    <label class='bottom-0' for='isSent'> Yes</label>
                                </div>
                                <div class='flex center'>
                                    <input class='left-20 color' type='radio' id='notSent' name='notSent' value='No'
                                           v-model='rec.collectionDetails.isSent' :disabled='hasSignature' />
                                    <label class='bottom-0' for='notSent'> No </label>
                                </div>
                            </div>
                        </div>
                        <div class="pad-5">
                            <div for=''>Date Sent</div>
                            <input type='date'
                                   class='max500'
                                   id='dateSentToOutsideLab'
                                   name='dateSentToOutsideLab'
                                   v-model='rec.collectionDetails.dateSent'
                                   :disabled='disableDatePicker || hasSignature'
                            >
                        </div>
                    </div>
                </div>

                <!-- T O X   R E S U L T S  -->
                <div>
                    <div class='dark-text header-w-bg'>Toxicology Results</div>
                    <div class='bottom-10'>
                        <div v-for='(drug, index) in intoxicants' :key='drug' class='avoid-break flex center tox-substances'>
                            <div class='dark-text weight-600 flex-1'>{{ drug }}</div>
                            <div class='flex-2 flex center'>
                                <div class='flex center right-30'>
                                    <input class="color" type='radio' value='Positive' v-model='rec.results[intoxicants[index]]'
                                           :disabled='hasSignature'>
                                    <label class='right-20' for='positive'> Positive </label>
                                </div>
                                <div class='flex center right-30'>
                                    <input class='left-20 color' type='radio' value='Negative'
                                           v-model='rec.results[intoxicants[index]]' :disabled='hasSignature'>
                                    <label class='right-20' for='negative'> Negative </label>
                                </div>
                                <div class='flex center'>
                                    <input class='left-20 color' type='radio' value='Not Tested For'
                                           v-model='rec.results[intoxicants[index]]' checked :disabled='hasSignature'>
                                    <label class='right-20' for='notTested'> Not Tested For </label>
                                </div>
                            </div>
                        </div>

                        <div class='' v-if='newSubstance'>
                            <div class='top-15 bottom-15' v-for='(addedSubstance, index) in addedSubstances'
                                 :key='addedSubstance.id'>
                                <div class='weight-600 dark-text'>Other</div>
                                <div class='flex center'>
                                    <div class='flex-1'>
                                        <input class='' type='text' v-model='addedSubstances[index].name'
                                               :disabled='hasSignature' />
                                    </div>
                                    <div class='flex center flex-2 left-20'>
                                        <div class='flex center right-30'>
                                            <input class="color" type='radio' id='positive' value='Positive'
                                                   v-model='addedSubstances[index].result' :disabled='hasSignature'>
                                            <label for='positive'> Positive </label>
                                        </div>
                                        <div class='flex center right-30'>
                                            <input class="color" type='radio' id='negative' value='Negative'
                                                   v-model='addedSubstances[index].result' :disabled='hasSignature'>
                                            <label for='negative'> Negative </label>
                                        </div>
                                        <div class='flex center'>
                                            <input class="color" type='radio' id='notTested' value='Not Tested For'
                                                   v-model='addedSubstances[index].result' :disabled='hasSignature'>
                                            <label for='notTested'> Not Tested For </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button class='no-bg dark-text add' type='button' @click='addSubstance' :disabled='hasSignature'>
                        <span class='material-icons purple'>add_box</span> Add Substance
                    </button>
                </div>
                <div class='align-right' v-if='!signature_ids.length'>

                    <button v-if='original_signature_ids.length && !signature_ids.length' class='align-right'
                            type='button' @click='signature_ids=original_signature_ids'>
                        Cancel Edit
                    </button>
                    <ConfirmButton @click='updateRecord()' :canedit='isEditable' />

                </div>
                <FormSignature :user_id='user_id' :signature_ids='signature_ids' ref='form_signatures'
                               @getSignatures='getSignatures' v-if='signature_ids.length > 0' />
                <FormSignatureButton
                    :rec='rec'
                    :original_rec='original_rec'
                    :signatures_info='signatures_info'
                    :fileName='pageMeta.component_name'
                    :signature_ids='signature_ids'
                    :record_id='record_id'
                    @resetView='reloadData'
                    @fail='fail2sign'
                    @showModal='showPdfModal'
                    :save='updateRecord'
                />
            </form>

            <VIfWorkableModal
                :hasCloseButton='false'
                data-html2canvas-ignore='true'
                v-if='showCreatePdfModal'
            >
                <SignConvertPdfModal />
            </VIfWorkableModal>
        </div>
    </div>
</template>
<script>
    import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal'
    import FormClient from '@/components/formpieces/FormClient';
    import FormCompany from '@/components/formpieces/FormCompany.vue';
    import FormSignature from '@/components/formpieces/FormSignatureNew';
    import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew';
    import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
    import { dryalex } from '@/mixins/dryalex';
    import { merge } from 'lodash';

    import { clients, companies, toxicology, form_data, settings } from '@/util/apiRequests';
    import { mapState } from 'vuex';
    import Multiselect from 'vue-multiselect';

    export default {
        name: 'ToxicologyReport',
        props: {
            record_id: {
                type: [String, Number],
                required: false,
                default: 0
            }
        },
        components: { FormClient, FormSignature, VIfWorkableModal, FormCompany, FormSignatureButton, Multiselect, SignConvertPdfModal },
        data() {
            return {
                loading: 1,
                updating: 0,
                isEditable: 1,
                user_id: 0,
                client_id: 0,
                page_id: 0,
                rec: {},
                dayt: new Date(),
                original_rec: {},
                signature_ids: [],
                original_signature_ids: [],
                showCreatePdfModal: false,
                api_signs_needed: null,
                signatures_info: null,
                client: {},
                company: {},
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                this_record_id: 0,
                router_push: 0,


                pageId: null,
                clientId: null,
                clientData: {},
                companyData: { company_name: '', address: '', phone: '' },
                clinicians: [],
                staffPresent: null,
                idVerification: '',
                referralSource: null,
                //Medical
                medsOptions: [],
                medsReported: [],
                //Collection Details
                screeningOptions: ['Oral/Saliva', 'Urinanalysis', 'Breathalyzer'],
                drugsOptions: [],
                drugsReported: [],
                //tox results
                testResults: ['Positive', 'Negative', 'Not Tested For'],
                //make sure elements are identical to those in the backend (form_schema, pageid: 90)
                intoxicants: [
                    '6MAM - Heroin Metabilite',
                    'ALC - Alcohol',
                    'AMP - Amphetimines',
                    'BAR - Barbituates',
                    'BUP - Buprenorphine',
                    'BZO - Benzodiazepine',
                    'CLO - Clonaepam/Klonopin',
                    'COC - Cocaine',
                    'COD - Codeine',
                    'COT - Cotenine',
                    'EDDP -  Methadone Metabolite',
                    'ETG - Ethyl Glucuonide',
                    'FEN/FTY - Fentanyl',
                    'GABA - Gabapentin',
                    'GHB - Gamma Hydroxybutyrate',
                    'K2 - Synthetic Cannabinoid',
                    'K3 - Synthetic Cannabinoid',
                    'KET - Ketamine',
                    'KRA - Kratome',
                    'LSD - Lysergic Acid Diathylamide',
                    'mAMP/MET - Methamphetamine',
                    'MOR - Morphine',
                    'MPD - Methylphenidate',
                    'MTD - Methadone',
                    'MQL - Methylequalone',
                    'NIC - Nicotine',
                    'OPI - Opiates',
                    'OXY - Oxycodone',
                    'PCP - Phencyclidine',
                    'PGB - Pregabala/Lyrica',
                    'PPX - Propoxyphene',
                    'TCA - Tricyclic Antidepressants',
                    'THC - Marijuana',
                    'TRA/TRM - Tramadol'
                ],
                //add new substance
                newSubstance: false,
                addedSubstances: [],
                toxData: null
            };
        },
        computed: {
            disableDatePicker() {
                // disable if the collection has NOT been sent outside the lab
                if (this.rec.collectionDetails.isSent == 'No') {
                    return true;
                } else return false;
            },

            hasSignature() {
                if (this.signature_ids.length) {
                    return true;
                }
                return false;
            }

        },
        methods: {
            async getClinicians() {
                try {
                    const res = await this.$api.get(companies.getClinicians(this.$store.state.user.company_id));
                    this.clinicians = res.data;
                } catch (err) {
                    console.log('err', err);
                    this.$toasted.error('Could not retrieve list of clinicians');
                }
            },
            clinicianName({ first_name, last_name }) {
                return `${first_name} ${last_name}`;
            },
            addMedTag(newTag) {
                const tag = {
                    name: newTag,
                    code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
                };
                this.rec.medications.medsOnFile.push(tag);
                this.medsOptions.push(tag);
                this.medsReported.push(tag);
            },
            removeMedTag(e) {
                this.rec.medications.medsOnFile = this.rec.medications.medsOnFile.filter((med) => med !== e);
            },
            addDrugTag(newTag) {
                const tag = {
                    name: newTag,
                    code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
                };
                this.rec.medications.reportedIntoxicants.push(tag);
                this.drugsOptions.push(tag);
                this.drugsReported.push(tag);
            },
            removeDrugTag(e) {
                this.rec.medications.reportedIntoxicants = this.rec.medications.reportedIntoxicants.filter((drug) => drug !== e);
            },
            addSubstance() {
                this.newSubstance = true;
                this.addedSubstances.push({
                    name: null,
                    result: null,
                    id: this.randomId()
                });
            },
            randomId() {
                return Math.floor(Math.random() * 10000 + 1);
            },
            async fail2sign() {
                this.showPdfModal(false);
            },
            async reloadData(signature) {
                this.showCreatePdfModal = false;
                this.signature_ids.push(signature);
            },
            showPdfModal(bool) {
                this.showCreatePdfModal = bool;
            },
            getClient(data) {
                this.client = data;
            },
            getCompany(data) {
                this.company = data;
            },
            //signatures that come from the FormSignatures component
            getSignatures(data) {
                this.signatures_info = data ? data : [];
            },
            async updateRecord(e) {
                if (!this.updating) {
                    this.updating = 1;
                    try {
                        e.preventDefault();
                    } catch (error) {/**/
                    }

                    let xthis = await dryalex.form_data_update_record(this, e);
                    merge(this, xthis);
                    if (this.router_push) {
                        this.router_push = 1;
                        this.$forceUpdate();
                    }
                    this.original_signature_ids = this.signature_ids;
                }
            },
            async init() {
                let xthis = await dryalex.form_data_init(this);
                merge(this, xthis);
                this.loading = 0;
                //get clinicians
                this.getClinicians();

                if (this.$route.params.id) {
                    this.staffPresent = this.rec.staffPresent;
                    this.medsReported = this.rec.medications.medsOnFile;
                    this.medsReported = this.rec.medications.medsOnFile;
                }

                if (!this.$route.params.record_id) {
                    const resultDefault = await this.$api.get(settings.getSetting('toxicology_results_default','company','company'));
                    let resultDefaultName = 'Not Tested For';
                    if (resultDefault.data.value > 0) {
                        resultDefaultName = 'Negative';
                    }
                    this.intoxicants.forEach((element, index) => {
                        this.rec.results[this.intoxicants[index]] = resultDefaultName;
                    });
                }
            },
            printView() {
                window.print();
            },
        },
        async created() {
            await this.init();
        },
        watch: {
            showCreatePdfModal(newVal) {
                if (window.innerWidth >= 768 && newVal == true) this.openModalOverlay();
                if (newVal == false) {
                    this.closeModalOverlay();
                }
            },
            staffPresent() {
                this.rec.staffPresent.id = this.staffPresent.id;
                this.rec.staffPresent.firstName = this.staffPresent.first_name;
                this.rec.staffPresent.lastName = this.staffPresent.last_name;

            },
            rec() {
               
            }

        }
    };
</script>
